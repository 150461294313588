// ************************************************
// GLOBAL
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
@import "vars";
@import "../global/mixins";
@import "../global/prism";

html, body {
    overscroll-behavior: none;
    overflow-x: hidden;
}
html {
    position: relative;
    min-height: 100%;
    width:100%;
}
body {
    font-family: 'Open Sans', sans-serif;
    background-color: $black;
    color: $white;
    // margin-bottom: ($footer-height * 2);
    // margin-bottom: $footer-height;
    // overflow-x :hidden;
    padding: 0;

    //Language Options
    .lang-tr {
        // opacity: .5;
    }
}
.footpad {
    margin-bottom: $footer-height;
}
.editpage {
    display: none;
    position: fixed;
    z-index: 99999;
    float: right !important;
    top: 50vh;
    right: 1rem;
    -webkit-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.75);
       -moz-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.75);
}

// ************************************************
// HEADER AND NAVBAR


header {
    min-height: $header-height;
    @media (min-width: 320px) and (max-width: 575.98px) {
        min-height: $header-height / 2;
    }
    background-color: $black;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .gradient {
        z-index: 9998;
        position: absolute;
        bottom:0;
        width: 100vw;
        height: $header-height / 3 ;
        @media (min-width: 320px) and (max-width: 767.98px) {
            height: $header-height / 6;
        }
        background: rgba(0, 0, 0, 0.0);
        background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 1.0));
        background: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 1.0));
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 1.0));
    }
    .header-img {
        height: $header-height;
        width: 100%;
        @media (min-width: 320px) and (max-width: 767.98px) {
            height: $header-height / 2;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        #carousel {
            min-height: $header-height;
            @media (min-width: 320px) and (max-width: 575.98px) {
                min-height: $header-height / 2;
            }
            .carousel-indicators {
                margin-bottom: 1rem;
                z-index: 99999;
                @media (min-width: 320px) and (max-width: 767.98px) {
                    margin-bottom: -.5rem;
                }
                li {
                    opacity: 1;
                    width: 1rem;
                    height: 1rem;
                    border-radius: 100%;
                    &.active {
                        background-color: $red;
                    }
                    @media (min-width: 320px) and (max-width: 575.98px) {
                        width: 1.3rem;
                        height: 1.3rem;
                        margin-left: .7rem;
                        margin-right: .7rem;
                    }
                }
            }
            .carousel-inner {
                width: 100%;
                .carousel-item {
                    height: $header-height;
                    width: 100%;
                    overflow: hidden;
                    // position: relative;
                    @media (min-width: 320px) and (max-width: 575.98px) {
                        height: $header-height / 2;
                    }
                    img {
                        // position: absolute;
                        margin: auto; 
                        min-height: 100%;
                        min-width: 100%;
                        // left: -100%;
                        // right: -100%;
                        // top: -100%;
                        // bottom: -100%;
                        @media (min-width: 320px) and (max-width: 575.98px) {
                            margin: auto; 
                            height: $header-height / 2 !important;
                            height: auto;
                            // left: -100%;
                            // right: -100%;
                            // top: -100%;
                            // bottom: -100%;
                        }
                    }
                }
            }
        }
    }
    .header-overlay {
        background: rgba(0, 0, 0, 0.0);
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 1.0));
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 1.0));
        background: linear-gradient(to top, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 1.0));
        background-size: 100% 15rem;
        background-repeat: no-repeat;
        z-index: 9999;
        position: absolute;
        width: 100vw;
        height: 100%;
        .seo-header {
            color: transparent;
        }
        .site-logo {
            position: absolute;
            left: 1rem;
            top: 1rem;
            max-height: 10rem;
            @include media-breakpoint-down(md) {
                padding-bottom: 1rem;
                max-height: 6rem;
            }
        }
        .language {
            position: absolute;
            right: 1rem;
            top: 1rem;
            font-size: 1.5rem;
            padding-top: .3rem;
            text-align: center;
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
            color: $white;
            background-color: rgba($black, .5);
        }
    }
    .tocategory {
        position: absolute;
        z-index: 99999;
        width: 100%;
        bottom: 3rem;
        @media (min-width: 575.98px) and (max-width: 991.98px) {
            bottom: 15rem;
        }
        .btn {
            display:inline-block;
            text-align: center;
            height: 5rem;
            width: 5rem;
            padding-top: 1rem;
            margin-left:.5rem;
            margin-right:.5rem;
            border-radius: 50%;
            border: 1px solid $white;
            color: $white;
            background-color: rgba($black, .5);
        }
    }

    a, a:hover {
        color: $white;
        text-decoration: none;
    }
}
.header-short {
    position: relative;
    min-height: 200px !important;
}
.header-homepage {
    position: relative;
    min-height: 100vh;
    .header-overlay {
        min-height: 100vh;
    }
}
#mobile-lang {
    z-index: 99999;
    position: absolute;
    top: 3rem;
    right: 2rem;
    text-align: center;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    color: $white;
    background-color: $black;
    padding-top: .5rem;
}
nav {
    z-index: 999999 !important;
    position: absolute !important;
    top: 3rem;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    height: $nav-bar-height;
    @include media-breakpoint-down(md){
        padding-left: 0 !important;
        padding-right: 0 !important;
        .nav-item {
            text-align: center;
        }
        .navbar-collapse {
            background-color: rgba($black, .8);
            padding: 1rem;
        }
        form {
            justify-content: space-between;
        }
        input {
            flex: 1;
            margin-right: 1rem;
        }
    }
    .navbar-toggler {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: .5rem;
        color: rgba($white, 1);
        border-color: rgba($red, .8) !important;
        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
        }
    }
    .dropdown-menu-right {
        @include media-breakpoint-down(md) {
            text-align: center !important;
            .fa-check-circle {
                display: none;
            }
        }
    }
    .dropdown-menu {
        border-top: 1px solid $gray;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }
    #journeynavbar {
        .navbar-nav {
            .nav-item {
                margin: 0 .7rem;
                &.active {
                    border-bottom: 2px solid $red;
                }
                .nav-link {
                    color: $white;
                }
            }
            .nav-lang {
                // padding-left:.1rem;
                text-align: center;
                height: 2.5rem;
                width: 2.5rem;
                border-radius: 50%;
                color: $white;
                // background-color: rgba($black, .5);
                background-color: $black;
                @include media-breakpoint-down(md) {
                    margin: auto;
                }
            }
        }
    }

}
.dropdown-menu {
    background-color: $dark;
    a {
        color: darken($white, 40%) !important;
    }
    .dropdown-item {
        color: $white !important;
        &:hover {
            background-color: $white;
            color: $black !important;
        }
    }
    .active {
        background-color: $gray;
        color: $black !important;
    }
}
.top-info {
    transition: all 500ms ease 0s;
    position: fixed;
    top: -#{$nav-bar-height};
    height: $nav-bar-height;
    padding: 1rem;
    z-index: 1061;
    background-color: $white;
    color: darken($gray, 40%);
    @include media-breakpoint-down(md){
        padding: 1rem .5rem .5rem .5rem ;
    }
    h6 {
        padding-right: 2rem;
        a {
            color: $white;
            img {
                max-height: 2rem;
                margin-left: 1.5rem;
            }
            &:hover {
                text-decoration: none;
            }
        }
        small {
            display: block;
            p {
                margin-bottom: 0 !important;
            }
        }
        @include media-breakpoint-down(md){
            font-size: .7rem !important;
            padding-right: 1rem;
            margin-left: -1rem;
            #scrolltop {
                display: none;
            }
        }
        span {
            cursor: pointer;
        }
    }
}
#nav-at-top {
    .navbar {
        transition: all 500ms ease 0s;
        top: $nav-bar-height;
    }
    .top-info {
        @include animation;
        top: 0;
    }
}

// ************************************************
// CONTENT AREA
.mce-content-body, // for TinyMCE Preview panel

#content {
    h1 {
        font-size: 2.3rem;
    }
    background-color: $black;
    // min-height: calc(100vh - (#{$header-height} + #{$footer-height} ));
    // min-height: calc(100vh + #{$footer-height} );
    // padding-top: 5rem;
    // margin-bottom: #{$footer-height};
    // padding-bottom: 5rem;
    // @include media-breakpoint-down(md){
    //     padding-bottom: $footer-height * 2 !important;
    // }

    .container {
        display: -webkit-flex;
        -webkit-flex-direction: column;
        display: flex;
        flex-direction: column;
    }
    .content-full-height {
        // For center and middle of Content area
        height: calc(100vh - (#{$nav-bar-height * 4} + #{$footer-height} ));
    }

    .container-menu {
        margin-right: 0;
        margin-left: auto;
        @include media-breakpoint-down(lg){
                margin-left: 1.7rem;
        }
        @include media-breakpoint-up(lg){
                margin-left: 14rem;
                margin-right: 14rem;
        }
    }
    
    .page-toptext-delivery {
        margin-right: 14rem;
        @include media-breakpoint-down(md){
            margin-right: 1rem;
    }
        h2 {
            font-size: 1.5rem;
            margin-bottom: 1.5rem;
        }
    }
    .page-toptext {
        // margin-right: 14rem;
        background-color: rgba($black, .7);
        border: 1px solid $white;
        // border: none;
        border-radius: 0;
        // text-align: center;
        .info {
            max-height: 2rem;
            width: auto;
        }
        &.alert-dismissible {
            padding-right: 1rem;
        }
        @include media-breakpoint-down(md){
            margin-right: 1rem;
        }
        h2 {
            font-size: 1.5rem;
            margin-bottom: 1.5rem;
        }
        p, a, h2, h3, h4, h5, h6, li, span {
            color: $white !important;
        }
        .close {
            padding-top: 0;
        }
    }

    .filters {
        border-bottom: 1px solid $white;

        .col {
            position: relative;
            bottom: -2px;
            text-align: center;
            a {
                margin-left: 2rem;
                margin-right: 2rem;
                @include media-breakpoint-down(lg){
                    margin-left: 2rem;
                    margin-right: 2rem;
                }
                display: inline-block;
                width: 80%;
                color: $white;
                background-color: transparent;
                &.active {
                    border-bottom: 3px solid $red;
                }
            }
        }
    }

    .main-category-title {
        padding-left: 14rem;
        padding-top: 3rem;
        padding-bottom: 1rem;
        position: relative;
        overflow: hidden;
        color: $red !important;

        font-family: 'Open Sans', sans-serif;
        font-size: 2rem;
        font-weight: 400;
        @include media-breakpoint-down(md) {
            padding-left: 1.5rem;
        }

        span {
            display: inline-block;
            vertical-align: baseline;
            zoom: 1;
            position: relative;
            padding: 0 .6rem;

            &:before, &:after {
                content: '';
                display: block;
                width: 100vw;
                position: absolute;
                top: 0.73em;
                border-top: 1px solid $gray;
            }

            &:before { right: 100%; }
            &:after { left: 100%; }
        }
    }

    .category-icon {
        display: inline-block;
        margin-bottom: .45rem;
        max-height: 2.5rem;
        vertical-align:middle;
    }

    .accordion {
        padding-bottom: 5rem;
        .card {
            background-color: $black;
            border: none;
            border-bottom: 2px solid $gray;
            .card-header {
                background-color: $black;
                border-bottom: none;
                border-radius: 0;
                .btn {
                    padding-left:.55rem;
                    font-size: 1.1rem;

                    font-weight: 500;
                    color: $white;
                    i { color:$white; }
                    .fas {
                        width: 1rem;
                    }
                    // .fa-none-icon, .fa-angle-double-down {
                    //     display: inline-block;
                    //     width: 1rem;
                    // }
                    &:hover, &:focus, &:active {
                        text-decoration: none !important;
                    }
                }
            }
            .card-body {
                background-color: $black;
                .menu-link {
                    cursor: pointer !important;
                    display: block;
                }
                .menu-liste {
                    @include media-breakpoint-up(md){
                        margin-right: 2rem;
                    }
                    color: $white;
                    border-bottom: dotted 1px $gray;
                    margin-left: 2rem;
                    padding-left: 0;
                    margin-top: 1rem;
                    padding-bottom: .5rem;
                    // &:last-child {
                    //     border-bottom: none;
                    // }
                    &:hover {
                        // background-color: lighten($theme-main, 30%) !important;
                    }
                    .menu-title {
                        padding-left: 0;
                    }
                    .menu-price {
                        text-align: right;
                    }
                    .menu-icon {
                        padding-left: 0;
                        margin-top: .5rem;
                        margin-bottom: .5rem;
                        img {
                            max-height: 2rem;
                        }
                    }
                    .menu-description {
                        padding-left: 0;
                        color: $gray;
                        p {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .menu-detail {
        @media (min-width: 320px) and (max-width: 767.98px) {
            margin-top: 2rem;
        }
        .detail-title {
            font-size: 1.8rem;
            font-family: 'Open Sans', sans-serif;
        }
        .detail-price {
            font-size: 1.5rem;
            margin-top: 1rem;
            // font-weight: bold;
        }
        .detail-icon {
            img {
                height: 2rem;
            }
        }
        .detail-description {
            color: $gray;
        }
        .detail-content {
            margin-top: 2rem;
            ul {
                padding-left: 0;
                li {
                    list-style: none;
                    padding-bottom: .5rem;
                    margin-bottom: 1rem;
                    border-bottom: 1px dotted $darkgray;
                    &:last-child {
                        border: none;
                    }
                    strong {
                        // display: inline-block;
                        float: right;
                        font-weight: normal !important;
                    }
                }
            }
        }
        .detail-back {
            max-width: 150px;
            background-color: $black;
            border: none;
            cursor: pointer !important;
            i {
                color: $red;
            }
        }
    }


    //Content Textarea
    h1, h2, h3, h4, h5, h6 {
        // margin-top: 2rem;
    }
    a, a:hover {
        color: $white;
        &:hover {
            text-decoration: none !important;
        }
        .category-link {
            background-color: $gray;
            color: $light;
            &:hover {
                text-decoration: none !important;
            }
        }
    }
    hr {
        background-color: $theme-main;
    }

    //Breadcrump
    #nav-breadcrumb {
        padding-left: 0 !important;
        padding-right: 0 !important;
        .breadcrumb {
            background-color: lighten($black, 95%);
            .breadcrumb-item {
                +:before {
                    font-family: "Font Awesome 5 Free"; font-weight: 900;
                    content: "\f105";
                    vertical-align:top;
                    color: $black;
                }
            }
            .active {
                color: $gray !important;
            }
            a {
                color: $darkgray;
                &:hover {
                    text-decoration: none;
                    color: $gray;
                }
            }
        }
    }

    //Images
    .menu-img {
        max-width: 230px;
        height: auto;
    }

    
    //Pagination
    #nav-pagination {
        .pagination {
            justify-content: center;
            padding-bottom: 2rem;
            // font-size: .8rem;
            .page-item {
                span {
                    color: $gray;
                }
                &:hover {
                    span {
                        color: $darkgray;
                    }
                }
            }
            .page-link {
                &:hover {
                    background-color: lighten($black, 95%);
                }
            }
            .active {
                .page-link {
                    background-color: lighten($black, 15%) !important;
                    border-color: lighten($black, 15%) !important;
                    color: $white;
                }
            }
        }
        .list-group-item-action:hover, .list-group-item-action:focus {
            background-color: lighten($black, 95%);
        }
    }

    //Galeri images
    #gallery {
        padding: 40px;
        .card-columns {
            column-gap: 1rem;
            .card {
                background-color: $black;
                border: none;
                border-radius: 0;
                margin-bottom: 1rem;
            }
        }
        .row > div {
            padding: 0;
            outline: 4px solid $black;
        }
        
        .lightbox {
            img {
                width: 100%;
                border-radius: 0;
                position: relative;
            }
            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -13px;
                margin-left: -13px;
                opacity: 0;
                color: #fff;
                font-size: 26px;
                font-family: 'Font Awesome 5 Free';
                content: '\f002';
                font-weight: 900;
                pointer-events: none;
                z-index: 9000;
                transition: 0.4s;
            }
            &:after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                background-color: rgba(0, 0, 0, 0.7);
                content: '';
                transition: 0.4s;
            }
            &:hover:after, &:hover:before {
                opacity: 1;
            }
        }
    }
}
#home-slider {
    background-color: $black;
    position: absolute;
    min-height: 100vh;
    min-width: 100vw;
    .carousel-inner {
        .carousel-item {
            
            img {
                object-fit: cover;
            }
        }
    }
}
.modal-backdrop {
    background-color: transparent;
}
#alertmodal {
    z-index: 999999999;
    
    .modal-dialog {
        -webkit-transform: translate(0,-50%);
        -o-transform: translate(0,-50%);
        transform: translate(0,-50%);
        top: 50%;
        margin: 0 auto;
        min-width: 66%;
        .modal-header {
            border: none;
            .close {
                color: $white;
            }
        }
        .close {
            color: $white;
            text-align: right;
        }
        .modal-content {
            text-align: center;
            padding: 2rem;
            border-radius: 0;
            background-color: rgba($black, .6);
            h2 {
                font-size: 1.5rem;
                margin-bottom: 2rem;
            }
            a {
                color: $red;
            }
        }
    }
    
}
.mce-content-body, // for TinyMCE Preview panel
#textarea {
    p, li {
        a {
            border-bottom: 1px solid $red;
            &:hover {
                text-decoration: none;
                border-bottom: 1px dotted $red;
            }
            &.tdn {
                text-decoration: none;
                border-bottom: none;
            }
        }
    }
    // .alert-danger {
    //     background-color: green;
    // }
    table {
        color: $white;
        margin-top: 2rem;
        tr {
            border-bottom: 1px solid $darkgray;
            td {
                border: none;
                padding-top: 1rem;
                padding-bottom: 1rem;
                vertical-align : middle;
                &:first-child {
                    text-align: center;
                    width: 3.5rem;
                    @include media-breakpoint-down(md) {
                        vertical-align : top;
                        padding-top: 1rem;
                    }
                }
                img {
                    max-height: 2.5rem;
                    @include media-breakpoint-down(md) {
                        max-height: 3rem;
                    }
                }
            }
        }
    }
}

// ************************************************
// FOOTER

footer {
    z-index: 99999;
    overflow-x: hidden;
    position: absolute;
    bottom: 0;
    min-height: $footer-height;
    width: 100%;
    // background-color: $red;
    background: rgba(0, 0, 0, 0.0);
    background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 1.0));
    background: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 1.0));
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 1.0));
    color: $white;
    // padding-top: 2rem;
    // margin-top:2rem;
    @include media-breakpoint-down(md) {
        bottom: -1px;
        text-align: center !important;
    }
    a, a:hover {
        color: $white;
    }
    .addressbar {
        height: calc( #{$footer-height} / 1.5);
        @include media-breakpoint-down(md) {
            min-height: calc( #{$footer-height} * 2);
        }
    }
    address {
        #mapModal .modal-dialog {
            -webkit-transform: translate(0,-50%);
            -o-transform: translate(0,-50%);
            transform: translate(0,-50%);
            top: 50%;
            margin: 0 auto;
        }
        div {
            margin-bottom: .5rem;
            i {
                display: inline-block;
                margin-top: .2rem;
            }
        }
    }
    .centermobile {
        @include media-breakpoint-down(md) {
            text-align: center !important;
        }
    }
    .footer-logo {
        max-height: 100px;
    }
}