//// Mixin
// coderesponsible . com / useful-sass-mixins
//Rounded Corners
@mixin border-radius($size) {
    -webkit-border-radius: $size + px;
    -moz-border-radius: $size + px;
    border-radius: $size + px;
}
// Linear Gradients
@mixin linear-gradient($fromColor, $toColor) {
    background-color: $toColor;
    /* Fallback Color */
    background-image: -webkit-gradient(linear, left top, left bottom, from($fromColor), to($toColor));
    /* Saf4+, Chrome */
    background-image: -webkit-linear-gradient(top, $fromColor, $toColor);
    /* Chrome 10+, Saf5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(top, $fromColor, $toColor);
    /* FF3.6 */
    background-image: -ms-linear-gradient(top, $fromColor, $toColor);
    /* IE10 */
    background-image: -o-linear-gradient(top, $fromColor, $toColor);
    /* Opera 11.10+ */
    background-image: linear-gradient(top, $fromColor, $toColor);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr=  '#{$fromColor}', EndColorStr='#{$toColor}');
}
//Opacity
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}
//Background Opacity
@mixin background-opacity($bgmenu, $opacity: 0.3) {
    background: $bgmenu;
    /* The Fallback */
    background: rgba($bgmenu, $opacity);
}
//Headers
$step-size-heading: 0.33333;
$amplifier-heading: 0.8;
@mixin heading-size($size) {
    font-size: $size * $step-size-heading * $amplifier-heading + em;
}
// REM Units with PX Fallback
@mixin rem($property, $values...) {
    $n: length($values);
    $i: 1;
    $pxlist: ();
    $remlist: ();
    @while $i <= $n {
        $itemVal: (nth($values, $i));

        @if $itemVal != "auto" {
            $pxlist: append($pxlist, $itemVal + px);
            $remlist: append($remlist, ($itemVal / 10) + rem);
        }
        @else {
            $pxlist: append($pxlist, auto);
            $remlist: append($remlist, auto);
        }
        $i: $i + 1;
    }
    #{$property}: $pxlist;
    #{$property}: $remlist;
}
// Retina Images
@mixin image-2x($image, $width, $height) {
     @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx){
        background-image: url($image);
        background-size: $width + px $height + px;
        background-size: $width / 10 + rem $height / 10 + rem;
    }
}
//File Icons
@mixin fileicon($icon) {
    font-family: "Font Awesome 5 Free"; font-weight: 900; 
    content: $icon;
    margin-right: .5rem;
    font-size: 1.3rem;
    color: $black;
}
// Section flex
@mixin sectionflex() {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
    padding-bottom: 3rem;
} 
//Global Animations
@mixin animation() {
    transition: all 500ms ease 0s;
}
//Read More
@mixin readmore($icon) {
    font-family: "Font Awesome 5 Free"; font-weight: 900; 
    content: $icon;
    background-color: $white;
    position: absolute;
    padding: .3rem 0 0 .6rem;
    margin-top: .5rem;
    margin-right: .5rem;
    border-radius: .25rem;
    width: 2rem;
    height: 2rem;
    right: 0; 
    bottom: .5rem;
}

