@import "../../assets/fontawesome/css/all.css";
@import "../../assets/fancybox/dist/jquery.fancybox.min.css";
@import "../../assets/bootstrap/scss/functions.scss";
@import "../../assets/bootstrap/scss/variables.scss";

$theme-main             :#5BB0E0;

$black                  :#000000;
$white                  :#ffffff;
$mainbg                 :#ecebeb;

$lightgray              :#F0F0EC;
$gray                   :#9CA5A7;
$darkgray               :darken( $dark, 10% );

$red                    :#ee321d;
$yellow                 :#FDBC40;
$green                  :#34C84A;
$blue                   :#5BB0E0;
$orange                 :#F28245;

$one-third              :31.66%;
$one-fourth             :23.115%;

$news-item-height       :300px;
$events-item-height     :330px;
$welcome-image-size     :400px;
$home-item-radius       :.25rem;
$image-radius           :.5rem;
$header-height          :600px;
$footer-height          :150px;
$nav-bar-height         :60px;

//Navbar Background overwrite
// $dropdown-bg            :$dark;
// $dropdown-link-color    :$light;

// $breadcrumb-divider: quote(">");
$theme-colors: (
    info: $theme-main
);

@import "../../assets/bootstrap/scss/bootstrap.scss";